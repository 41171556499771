<template>
  <div class="market-place-tabs">
    <Flickity ref="tabsFlickity" :options="tabsFlickityOpt" class="tabs-flickity">
      <router-link :to="{ name: 'MarketPlaceService'}" class="category-tab fs-16px fw-bold" :class="{'selected': $route.name === 'MarketPlaceService'}">
        <span>選購服務</span>
      </router-link>
      <router-link :to="{ name: 'MarketPlaceHistory'}" class="category-tab fs-16px fw-bold" :class="{'selected': $route.name === 'MarketPlaceHistory', 'to-do': hasValidStatus}">
        <span>服務紀錄</span>
      </router-link>
      <router-link :to="{ name: 'MarketPlaceRewards'}" class="category-tab fs-16px fw-bold" :class="{'selected': $route.name === 'MarketPlaceRewards'}">
        <span>回饋金管理</span>
      </router-link>
    </Flickity>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'ClothCategoryTabs',
  data() {
    return {
      selectingCategory: -1,
    }
  },
  created() {
  },
  components: {
    Flickity,
  },
  computed: {
    ...mapState('event', ['hasValidStatus']),
    ...mapState(['clothCategories']),
    tabsFlickityOpt() {
      return {
        wrapAround: false,
        cellAlign: 'left',
        freeScroll: true,
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        initialIndex: 0,
      };
    },
    // tabsOpts() {
    //   const res = [
    //     {
    //       name: '全部',
    //       value: -1,
    //     }
    //   ];
    //   for (const c of this.clothCategories) {
    //     res.push({
    //       name: c.name,
    //       value: c.serial,
    //     });
    //   }
    //   return res;
    // },
  },
  mounted() {
  },
  props: {
  },
  watch: {
    
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData', 'removeFromHistoryListData']),

    tabClicked(tab) {
      // console.log(tab);
      this.selectingCategory = tab.value;
      this.$emit('set', tab);
    }
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
  .cloth-category-tabs {
    position: relative;
    padding: .25rem 0;
  }

  .tabs-flickity {
    /* padding: .25rem; */
  }

  .tabs-flickity:focus-visible {
    outline: none;
  }

  .category-tab {
    position: relative;
    padding: .125rem .75rem;
    margin: .5rem 0;
    font-weight: bold;
    color: var(--text-color-main);
    &.selected span {
      position: relative;
      &:before {
        position: absolute;
        bottom: -.25rem;
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--color-brand-primary);
      }
    }
    &.to-do:before {
      display: block;
      position: absolute;
      top: 1px;
      right: 5px;
      content: '';
      width: .5rem;
      height: .5rem;
      border-radius: 100%;
      background: $typography-primary-error;
    }
  }

  .category-tab:not(:first-child) {
    /* margin-left: 1.5rem; */
  }

  a {
    text-decoration: none;
  }
</style>
<style lang="scss">
@import "src/assets/scss/basic";
</style>
