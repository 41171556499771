<template>
  <div class="market-place">
    <MarketPlaceTabs></MarketPlaceTabs>
    <div class="market-place-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import MarketPlaceTabs from "@/components/share/MarketPlaceTabs.vue";

export default {
  name: 'MarketPlace',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
      followingSerials: [],

      showAddFriendModal: false,
    };
  },
  components: {
    MarketPlaceTabs,
  },
  computed: {
    ...mapState('event', ['historyListData', 'hasValidStatus']),
  },
  watch: {
  },
  async mounted() {
    await this.refreshHistoryList();
    await this.redirectMethod()
  },
  beforeRouteLeave(to, from, next) {
    this.clearHistoryListData();
    next();
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData', 'removeFromHistoryListData']),
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),

    async redirectMethod() {
      if (this.hasValidStatus) {
        await this.$router.push({ name: 'MarketPlaceHistory' });
      } else {
        await this.$router.push({ name: 'MarketPlaceService' });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.market-place {
  padding: 0 .5rem;
}
</style>
  